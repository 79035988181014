<template>
  <ValidationObserver v-slot="{ errors, reset }">
    <ApplyCreditTabs
      :tab="tab"
      @changeTab="changeTab"
      class="justify-end py-1"
    />

    <BaseForm class="dark_blue elevation-5 pa-1" v-if="tab === 0">
      <template v-slot:fields>
        <v-col
          cols="12"
          class="py-1"
          v-for="{ value, label, type, rules } in fields"
          :key="value"
        >
          <DatePicker
            v-if="type === 'datepicker'"
            v-model="obj[value]"
            :data="obj"
            :keyData="value"
            :title="label"
            :existingModel="obj[value]"
            :disabled="disabled"
          ></DatePicker>
          <v-tooltip
            v-else
            bottom
            :disabled="
              !(errors && errors[`${label}`] && errors[`${label}`].length)
            "
            color="warning"
          >
            <template v-slot:activator="{ on }">
              <ValidationProvider
                :rules="rules"
                :name="label"
                validate-on="change"
                v-slot="{ errors }"
              >
                <v-text-field
                  v-on="on"
                  type="text"
                  v-model="obj[value]"
                  outlined
                  dense
                  background-color="darker_blue"
                  color="white"
                  :label="label"
                  hide-details
                  :error-messages="errors[0]"
                  :disabled="disabled"
                ></v-text-field>
              </ValidationProvider>
            </template>
            <span class="font-weight-bold dark_blue--text">{{
              !!(errors && errors[`${label}`] && errors[`${label}`].length)
                ? errors[`${label}`][0]
                : ""
            }}</span>
          </v-tooltip>
        </v-col>
      </template>
      <template v-slot:buttons>
        <v-col class="py-0 px-1 text-center">
          <v-btn
            color="primary"
            class="elevation-5 px-2 py-0 button"
            @click="sendData"
            block
            :disabled="disabled"
          >
            Save
          </v-btn>
        </v-col>
        <v-col class="py-0 px-1 text-center">
          <v-btn
            @click="reset"
            class="elevation-5 px-2 py-0 button button--outlined"
            block
            :disabled="disabled"
          >
            Reset
          </v-btn>
        </v-col>
      </template>
    </BaseForm>
    <div v-if="tab === 1">
      <NewOrdersCredit
        class="flex-col"
        col="12"
        tableHeigth="15vh"
        :append="{ totalAmount: false, balance: true }"
      />
      <v-tooltip
        bottom
        color="warning"
        :disabled="
          !!creditsSelected.length &&
          !(creditsSelectedBalance >= tabDetails.vit_vendorTotal - credits)
        "
      >
        <template v-slot:activator="{ on }">
          <div v-on="on">
            <v-btn
              v-on="on"
              color="primary"
              class="elevation-5 px-2 py-0 button"
              @click="saveCredit"
              block
              :disabled="
                !creditsSelected.length ||
                creditsSelectedBalance > tabDetails.vit_vendorTotal - credits
              "
            >
              Save
            </v-btn>
          </div>
        </template>
        <span>{{
          !creditsSelected.length
            ? "Credit must be selected"
            : "Credit amount cannot be greater than the total amount of the order"
        }}</span>
      </v-tooltip>
    </div>
  </ValidationObserver>
</template>

<script>
import BaseForm from "@/components/General/BaseForm.vue";
import DatePicker from "@/components/General/DatePickerGeneral.vue";
import { mapActions, mapState, mapGetters, mapMutations } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ApplyCreditTabs from "@/components/OM/Details/ApplyCreditTabs.vue";
import NewOrdersCredit from "@/components/OM/AllOrders/NewOrdersCredit.vue";

export default {
  components: {
    BaseForm,
    DatePicker,
    ValidationProvider,
    ValidationObserver,
    ApplyCreditTabs,
    NewOrdersCredit,
  },
  props: ["disabled"],
  data() {
    return {
      docNo: null,
      orderID: null,
      fields: [
        { label: "Amount", value: "amount", rules: "double" },
        { label: "Reference", value: "reference" },
        { label: "Remarks", value: "remarks" },
      ],

      obj: {
        order_from: "",
        invoice_no: "",
        amount: "",
        order_to: "",
        reference: "",
        remarks: "",
      },
      tab: 0,
    };
  },
  async created() {
    await this.initLocalState();
  },
  computed: {
    ...mapState({
      creditsSelected: (state) => state.moduleOrders.creditsSelected,
      paymentDetails: (state) =>
        state.moduleOrders.moduleDetails.paymentDetails,
    }),
    ...mapGetters({
      tabDetails: "moduleOrders/tabDetails",
      creditsSelectedBalance: "moduleOrders/creditsSelectedBalance",
    }),
    a() {
      return this.creditsSelectedBalance > this.credits;
    },
    credits() {
      let total = 0;
      this.paymentDetails
        .filter((x) => x.type === "REFUND" || x.type === "CREDIT")
        .map((x) => (total += Number(x.amount_paid)));
      return total;
    },
  },
  methods: {
    ...mapMutations({
      setCredits: "moduleOrders/setCredits",
      setCreditsSelected: "moduleOrders/setCreditsSelected",
    }),
    ...mapActions({
      applyCredit: "moduleOrders/applyCredit",
      getPaymentDetails: "moduleOrders/moduleDetails/getPaymentDetails",
      getOrderByDocNo: "moduleOrders/moduleDetails/getOrderByDocNo",
      updateDetails: "moduleOrders/moduleDetails/updateDetails",
    }),

    async initLocalState() {
      const { id, docNo } = await this.tabDetails;
      this.docNo = docNo;
      this.orderID = id;

      this.obj.invoice_no = this.docNo;
    },

    async reset() {
      for (let key in this.obj) this.obj[key] = "";

      await this.initLocalState();
    },
    sendData() {
      this.obj.order_from = [
        {
          invoice_no: this.docNo,
          amount: this.obj.amount,
          reference: this.obj.reference,
          remarks: this.obj.remarks,
        },
      ];
      this.obj.order_to = this.docNo;

      let data = {
        ...this.obj,
      };

      this.applyCredit({ data }).then((x) => {
        this.updateDetails({ name: "" });
      });
      this.reset();
    },
    async saveCredit() {
      try {
        await Promise.all(
          this.creditsSelected.map((x) => {
            return this.applyCredit({
              data: {
                order_from: [
                  {
                    invoice_no: x.invoice_no,
                    amount: x.balance,
                  },
                ],
                order_to: this.docNo,
              },
            });
          }),
        );

        this.updateDetails({ name: "" });
        this.setCreditsSelected([]);
      } catch (error) {
        console.log(error);
      }
    },
    changeTab(index) {
      this.tab = index;
    },
  },
};
</script>

<style lang="scss"></style>
