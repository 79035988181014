<template>
  <v-autocomplete
    v-model="obj[objKey]"
    :items="[...items, obj[objKey]]"
    :loading="isLoading"
    :search-input.sync="search"
    :label="label"
    color="white"
    outlined
    dense
    background-color="darker_blue"
    hide-details
    item-value="EquipmentID"
    item-text="EquipmentID"
    append-icon=""
    class="rounded-0"
  >
    <template v-slot:prepend-inner="">
      <EquipmentDetails :obj="stateObj" />
    </template>
  </v-autocomplete>
</template>

<script>
import { mapActions, mapState } from "vuex";
import EquipmentDetails from "@/components/OM/Details/EquipmentDetails.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  props: ["obj", "objKey", "label", "entry", "equipmentOnOrder", "stateObj"],
  components: {
    EquipmentDetails,
    ValidationProvider,
  },
  data: () => ({
    descriptionLimit: 100,

    isLoading: false,
    search: null,
    count: 0,
  }),

  computed: {
    value() {
      return this.obj[this.objKey];
    },
    items() {
      let containersNo = [];
      this.equipmentOnOrder.map((values) => {
        if (Array.isArray(values))
          values.map((x) => containersNo.push(x.ContainerNo));
        else {
          containersNo.push(values.ContainerNo);
        }
      });

      let values = this.entry.filter((entry) => {
        return (
          this.value === entry.EquipmentID ||
          !containersNo.includes(entry.EquipmentID)
        );
      });

      return values.length > this.descriptionLimit
        ? values.slice(0, this.descriptionLimit)
        : values;
    },
  },
  methods: {
    ...mapActions({
      getEquipmentsFiltered: "moduleOrders/moduleDetails/getEquipmentsFiltered",
    }),
  },
};
</script>

<style lang="scss"></style>
