<template>
  <div>
    <Actions @saveQuote="saveQuote" />
    <Details :detailsFields="detailsFields" :values="values" />
  </div>
</template>

<script>
import Details from "@/components/OM/Quotes/Details.vue";
import Actions from "@/components/OM/Quotes/Actions.vue";
import { mapActions, mapState } from "vuex";
import { parseFromIsoToFormat } from "@/helpers/mocks/dates.js";
export default {
  components: {
    Details,
    Actions,
  },
  // async created() {
  //   for (let value in this.values) {
  //     this.values[value] =
  //       value === "orderTypeId"
  //         ? await this.orderTypeId("Yard Buy")
  //         : value === "creditTermId"
  //         ? await this.creditTermId("Cash in Advance")
  //         : this.tabDetails[value];
  //   }
  // },
  computed: {
    ...mapState({
      tabSelected: (state) => state.moduleOrders.tabSelected,
      ordersType: (state) => state.moduleOrders.ordersType,
      ordersTerm: (state) => state.moduleOrders.ordersTerm,
    }),
    tabDetails() {
      return this.tabSelected.details;
    },
    detailsFields() {
      let values = [
        {
          title: "External Customer Number #",
          value: "externalCustomerNumber",
          type: "textfield",
        },

        {
          title: "Contract Number Reference #",
          value: "contractNumberReference",
          type: "textfield",
        },

        {
          title: "Customer PO",
          value: "custPoRef",
          type: "textfield",
        },
        {
          title: "Order Type",
          value: "orderTypeId",
          type: "dropdown",
          drowpdownValues: {
            text: "typeDesc",
            value: "id",
            values: this.ordersType,
          },
        },
        {
          title: "Credit Term",
          value: "creditTermId",
          type: "dropdown",
          drowpdownValues: {
            text: "termsDesc",
            value: "id",
            values: this.ordersTerm,
          },
        },
      ];

      this.tabDetails.docType !== "Quote"
        ? values.push({
            title: "Due Date",
            value: "dueDate",
            type: "datepicker",
            hideDetails: true,

            order: 4,
          })
        : null;

      return values;
    },
  },
  data() {
    return {
      values: {
        contractNumberReference: "",
        externalCustomerNumber: "",
        custPoRef: "",
        orderTypeId: "",
        creditTermId: "",
        dueDate: "",
      },
    };
  },
  watch: {
    tabDetails: {
      immediate: true,
      async handler() {
        this.values = {
          contractNumberReference: "",
          externalCustomerNumber: "",
          custPoRef: "",
          orderTypeId: "",
          creditTermId: "",
          dueDate: "",
        };
        for (let value in this.values) {
          this.values[value] =
            value === "orderTypeId"
              ? await this.orderTypeId("Yard Buy")
              : value === "creditTermId"
              ? await this.creditTermId("Cash in Advance")
              : value === "dueDate"
              ? parseFromIsoToFormat(this.tabDetails[value], "yyyy-LL-dd")
              : this.tabDetails[value];
        }
      },
    },
  },
  methods: {
    ...mapActions({
      creditTermId: "moduleOrders/creditTermId",
      orderTypeId: "moduleOrders/orderTypeId",
      save_quote: "moduleOrders/save_quote",
    }),
    saveQuote() {
      this.save_quote({
        ...this.values,
        docType: this.tabDetails.docType,
        docId: this.tabDetails.id,
        invoice_no: this.tabDetails.docNo,
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
