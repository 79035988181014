<template>
  <div
    class="rounded"
    :style="{
      border:
        infoMessage && infoMessage.panel === 1 ? 'thin solid #ffc107 !important' : '',
    }"
  >
    <CardDetails :fields="details" :obj="obj">
      <template v-slot:[`value_sent`]="{ item }">
        <v-icon v-if="item == 'Y'" color="green">mdi-checkbox-marked</v-icon
        ><v-icon v-else color=""></v-icon>
      </template>
    </CardDetails>

    <v-row>
      <v-col class="pa-2">
        <v-btn
          class="button elevation-5"
          small
          color="green"
          outlined
          style=""
          block
          @click="printViewBOS"
        >
          View/Send Bill Of Sale
        </v-btn>
      </v-col>
      <v-col class="pa-2">
        <v-btn
          class="button elevation-5"
          small
          color="green"
          outlined
          style=""
          block
          @click="printViewBOS"
        >
          Pay Reminder
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pa-2 container">
        <v-switch
          inset
          class="dark_blue elevation-0"
          flat
          dense
          v-model="displayOnBOS"
          label="MR Comments on BOS"
          @change="updateMRCommentSetting()"
        ></v-switch>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import CardDetails from "@/components/General/CardDetails.vue";
import { processSALDatesCountry } from "@/helpers/utils/index.js";

export default {
  components: {
    CardDetails,
  },
  computed: {
    ...mapState({
      infoMessage: (state) => state.moduleOrders.moduleDetails.infoMessage,
    }),
    ...mapGetters({
      tabDetails: "moduleOrders/tabDetails",
      uniqueDepotLineItems: ["moduleOrders/moduleDetails/uniqueDepotLineItems"],
    }),
    obj() {
      return {
        ...this.tabDetails,
        header: this.salRef,
        vit_vendorTotal: `$ ${parseFloat(this.tabDetails.vit_vendorTotal).toFixed(2)}`,
      };
    },
  },
  data() {
    return {
      salRef: "",
      country: "",
      displayOnBOS: false,
      details: [
        {
          text: "Bill of Sale / Order Ref ",
          value: "nereus_no",
        },
        {
          text: "Customer PO",
          value: "custPoRef",
        },
        {
          text: "Ordered By",
          value: "origin_login_name",
        },
        { text: "Order Total", value: "vit_vendorTotal" },
        { text: "Order SAL Ref", value: "header" },
        { text: "Bill of Sale Sent", value: "sent" },
        // { text: "Bill of Sale", value: "BillofSale" },
      ],
    };
  },
  async created() {
    let det = await this.tabDetails;
    det.date = det.docDate;

    let us = 0;
    let ca = 0;
    for (let i = 0; i < this.uniqueDepotLineItems.length; i++) {
      this.uniqueDepotLineItems[i].Country === "US" ? us++ : ca++;
      if (us > 0 && ca > 0) break;
    }

    this.country = us > 0 ? "US" : "CA";

    const processSALDates_res = await processSALDatesCountry([det], this.country);

    this.salRef = processSALDates_res[0].header;

    // this.displayOnBOS = thi.obj.bill_of_sale === "Y" ? true : false;
  },
  async mounted() {
    this.displayOnBOS = this.obj.bill_of_sale === "Y" ? true : false;
  },
  methods: {
    ...mapActions({
      getOrderByDocNo: "moduleOrders/moduleDetails/getOrderByDocNo",
      updateDetails: "moduleOrders/moduleDetails/updateDetails",
      updateDisplayMRCommentSetting:
        "moduleOrders/moduleDetails/updateDisplayMRCommentSetting",
      refreshAllPanels: "moduleOrders/moduleDetails/refreshAllPanels",
      printViewBOSAction: "moduleOrders/moduleDetails/printViewBOS",
    }),

    async printViewBOS() {
      await this.printViewBOSAction(this.salRef);
    },
    async updateMRCommentSetting() {
      const data = {
        value: this.displayOnBOS ? "Y" : "N",
        invoice_no: this.obj.docNo,
      };

      await this.updateDisplayMRCommentSetting(data);
      await this.refreshAllPanels({ docNo: this.obj.docNo, id: this.obj.id });
      // await this.getOrderByDocNo(this.obj.docNo);
    },
  },
};
</script>

<style scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
