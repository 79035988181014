<template>
  <v-row class="pa-0 elevation-5">
    <v-col
      class="col-ajust"
      v-for="({ title, disable }, index) in values"
      :key="title"
    >
      <v-btn
        small
        class="px-5 py-2 ma-0 primary--text rounded-0 text-lg-caption font-weight-bold"
        :class="index === tab ? 'primary dark_blue--text bold-text' : ''"
        dense
        block
        style="
          border-left: thin solid #3284f4;
          border-right: thin solid #3284f4;
        "
        :disabled="disable"
        @click="$emit('changeTab', index)"
      >
        <span class="text-capitalize">
          {{ title }}
        </span>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: ["tab"],
  computed: {
    values() {
      let tabValues = [
        {
          title: "Refund",
        },

        {
          title: "Credit Order",
        },
      ];
      return tabValues;
    },
  },
  data() {
    return {};
  },
};
</script>

<style></style>
